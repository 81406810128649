/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
// @ts-nocheck
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  Fade,
  Chip,
  Input,
  Popover,
  Tooltip,
  TableRow,
  TableCell,
  TextField,
  IconButton,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { DebounceInput } from 'react-debounce-input';

import {
  Done,
  AddRounded,
} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { formatMoney, numberFormat } from '../../../helpers/tools';
import {
  getTotalPrice,
  canDecreaseProductInCart,
  multiploVenta,
} from '../../../helpers/cart';

import CartListOptions from './CartListOptions';
import Percentage from '../../Discount/_components/constructor/components/mask/PercentageMask';
import {
  changeSinStock,
  updatePriceByPricebook,
  updateProductPriceInCart,
  updateProductQuantityInCart,
} from '../../../redux/actions/cartActions';
import useDebounce from '../../../hooks/use-debounce';
import ProductSerials from '../../rkr2-components/cart/components/ProductSerials';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CartListRow = React.memo(({
  classes,
  product,
  rowClasses,
  handleKeyTopSearchBar,
  dispatchDecreaseProductInCart,
  dispatchIncreaseProductInCart,
  dispatchRemoveProductInCart,

}) => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [singleDiscount, setSingleDiscount] = React.useState(0);
  const [discountAnchor, setDiscountAnchor] = React.useState(null);
  const [commentZone, setCommentZone] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [promo, setPromo] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(
    Math.abs(product.quantity) || 1,
  );
  const [productStock, setProductStock] = React.useState({});
  const [qtyTotal, setQtyTotal] = React.useState(0);

  const posTypeTab = useSelector(state => state.tabs.posTypeTab);
  const products = useSelector(state => state.product.products);
  const enableEditOrder = useSelector(state => state.order.enableEditOrder);
  const cartActionSelected = useSelector(state => state.posv2.cartActionSelected);

  const {
    productsWidthpromotions,
    productsStock,
    cartProductsByArray,
    selectedGuide,
    orderSelected,
    products: cartProducts,
  } = useSelector(state => state.cart);
  const user = useSelector(state => state.authUser.user);
  const {
    shipping_id,
    config: { pos_sininv, pos_auto_alm, pos_can_change_price },
    product_image_default,
  } = user;

  const debounceInputRef = useRef();
  const previewInputRef = useRef();

  const debouncevalue = useDebounce(inputValue, 300);

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line no-unused-vars

  const isOrder = Boolean(JSON.stringify(orderSelected) === JSON.stringify({}));
  const isEditOrder = Boolean(
    enableEditOrder && orderSelected.status !== 'Cancelado',
  );

  const productid = product.crmid
    ? product.crmid
    : product.productid && product.productid.indexOf('x') !== -1
      ? product.productid.split('x').pop()
      : product.productid;

  const hasPromotions = () => {
    let promotion = false;
    if (productsWidthpromotions && productsWidthpromotions.length) {
      const index = productsWidthpromotions.findIndex(
        prod => prod.productid === product.crmid,
      );
      if (index !== -1) {
        promotion = productsWidthpromotions[index].TreebesPromociones.length > 0
          || productsWidthpromotions[index].TreebesPromociones_unit_price_name
            !== null;
      }
    }
    setPromo(promotion);
  };

  const calculateStock = () => {
    let qty = 0;
    cartProducts.map((prod) => {
      const currentProductId = prod.crmid
        ? prod.crmid
        : prod.productid && prod.productid.indexOf('x') !== -1
          ? prod.productid.split('x').pop()
          : prod.productid;

      if (currentProductId === productid) {
        // eslint-disable-next-line radix
        qty += Number.parseInt(prod.quantity);
      }
    });

    if (
      (orderSelected.status === 'Creado' || isOrder)
      && productsStock[productid]?.qtyinstock - qty < 0
    ) {
      dispatch(changeSinStock(true));
    }
    setProductStock(productsStock[productid]);
    setQtyTotal(qty);
  };

  const updateProductQty = (newValue) => {
    if (!newValue || newValue === 'NaN') {
      return;
    }
    const qty = parseFloat(newValue);
    if (qty >= 0.1) {
      setInputValue(qty);
      dispatch(updateProductQuantityInCart(product.row, qty));
    } else {
      setInputValue(1);
      dispatch(updateProductQuantityInCart(product.row, 1));
    }
  };

  const handleChangeInput2 = () => {
    const commingValue = parseFloat(inputValue);
    const isUp = !previewInputRef.current || previewInputRef.current < commingValue;
    previewInputRef.current = commingValue;
    const salesUnitFormat = parseFloat(product.sales_unit || 1) || 1;
    if (commingValue < salesUnitFormat) {
      updateProductQty(salesUnitFormat);
      return;
    }

    if (commingValue >= 1) {
      const finalValue = multiploVenta(salesUnitFormat, commingValue, isUp);
      updateProductQty(parseFloat(finalValue).toFixed(2));
      return;
    }
    if (debounceInputRef && debounceInputRef.current) {
      debounceInputRef.current.value = Math.abs(inputValue);
    } else {
      updateProductQty(parseFloat(product.sales_unit).toFixed(2) || 1);
    }
  };

  /**
   * Actions
   */
  const dispatchUpdateProductPriceInCart = (event, changeFromCart = false) => {
    const { row } = event.target.dataset;
    const price = Number(event.target.value);
    dispatch(updateProductPriceInCart(row, Math.abs(price), changeFromCart));
  };

  const dispatchSetComment = prod => dispatch(setComment(prod));
  const dispatchSetSingleDiscount = (row, percentage) => dispatch(setSingleDiscount(row, percentage));
  const getPriceByPricebook = (row, pricebook) => dispatch(updatePriceByPricebook(row, pricebook));

  /**
   * Hooks
   */
  useMemo(() => {
    if ((posTypeTab === 'Vender' || enableEditOrder) && Math.abs(product.quantity) !== debouncevalue) {
      handleChangeInput2();
    }
  }, [debouncevalue]);

  React.useEffect(() => {
    if (!cartProducts.length || cartActionSelected !== 'CobrarVender') return;
    const prodt = cartProducts.find((p) => {
      const id = p.crmid || p.productid.split('x').pop();
      const prodId = product.crmid || product.productid.split('x').pop();
      return id === prodId;
    });

    setInputValue(
      prodt.series && prodt.series.length ? prodt.series.length : prodt.quantity,
    );
  }, [cartProducts, cartActionSelected, product]);

  React.useEffect(() => {
    setInputValue(product.quantity);
  }, [product]);

  React.useEffect(() => {
    setComment(product.comment);
    hasPromotions();
  }, [product.comment, productsWidthpromotions]);

  React.useEffect(() => {
    calculateStock();
  }, [cartProducts, productsStock, orderSelected]);


  /* Functions */
  const handleComment = (e) => {
    const {
      target: { value },
    } = e;
    setComment(value);
  };
  const handleKeyPress = async (e) => {
    const { charCode } = e;
    const { crmid } = product;
    if (charCode === 13) {
      await dispatchSetComment({ crmid, comment });
      await setCommentZone(false);
    }
  };
  const fetchComment = async () => {
    const { row } = product;
    await dispatchSetComment({ row, comment });
    await setCommentZone(false);
  };
  const onBlurComment = () => fetchComment();
  const removeDiscount = () => {
    dispatchSetSingleDiscount(product.row, 0);
  };
  function setSingleDiscountOnChange(e) {
    const {
      target: { value },
    } = e;
    const percentage = parseFloat(value) || 0;
    setSingleDiscount(percentage);
  }
  function setSingleDiscountOnBlur() {
    const { row } = product;
    const percentage = singleDiscount;
    dispatchSetSingleDiscount(row, percentage);
  }
  function setSingleDiscountKeyPress(e) {
    const { charCode } = e;
    if (charCode === 13) {
      setDiscountAnchor(null);
    }
  }
  const openPop = Boolean(discountAnchor);
  const idPop = openPop ? 'discount-popover' : undefined;
  const edit = isOrder || enableEditOrder;
  const existDiscount = Boolean(product.discount_percent > 0);

  const enablePrice = Boolean(
    posTypeTab === 'Vender' || (!existDiscount && enableEditOrder),
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = async () => {
    const { crmid, row } = product;
    await dispatchSetComment({ crmid, comment, row });
    handleClose();
  };
  let timeout = null;
  let countTimeout = null;

  const getProductImage = (crmid) => {
    const index = products.findIndex(item => item.crmid === crmid);

    if (index !== -1 && products[index].images.length) {
      return products[index].images[0];
    }

    const ProductIndex = cartProductsByArray.findIndex(
      item => item.crmid === crmid,
    );
    if (
      ProductIndex !== -1
      && cartProductsByArray[ProductIndex].productcode === 'ENVIO'
      && Object.keys(selectedGuide) > 0
    ) {
      return selectedGuide.carrier_logo_url;
    }

    return product_image_default;
  };

  const handleProductImage = (prod) => {
    if (prod.product_name === 'DESCUENTO POR PUNTOS RECOMPENZA') {
      return (
        <img style={{ width: 50, borderRadius: 5, marginRight: 2 }} alt="im" src={`${process.env.PUBLIC_URL}/img/reward-pints.webp`} />
      );
    }

    if (prod.productcode === 'ENVIO') {
      return (
        <img style={{ width: 50, borderRadius: 5, marginRight: 2 }} alt="im" src={`${product_image_default || `${process.env.PUBLIC_URL}/img/no_image.jpg`}`} />
      );
    }

    return <img style={{ width: 50, borderRadius: 5, marginRight: 2 }} src={prod.image && prod.image !== '/img/no_image.jpg' ? prod.image : getProductImage(prod.crmid)} onError={`this.onerror=null;this.src=${product_image_default}`} alt="Imagen del producto" />;
  };

  return (
    <>
      <TableRow
        tabIndex={0}
        className={`${rowClasses[product.crmid] || ''} ${
          !pos_sininv
          && (orderSelected.status === 'Creado' || isOrder || isEditOrder)
          && Number.parseInt(productStock?.qtyinstock) - qtyTotal < 0
            ? 'out-of-stock'
            : ''
        } ${promo ? 'has-promotion' : 'no-promotion'}
        `}
        style={{
          height: 69,
          position: 'relative',
          zIndex: cartProducts.length - product.row,
          backgroundColor: 'white',
        }}
        onKeyUp={handleKeyTopSearchBar}
        data-crmid={product.crmid || productid}
        data-row={product.row}
      >
        {commentZone && (
          <TableCell colSpan={4} align="center" className="m-0">
            <TextField
              autoFocus
              disabled={!edit}
              value={comment}
              variant="outlined"
              multiline
              className="w-100 h-100 pr-4"
              placeholder="Escriba un comentario al producto"
              InputProps={{
                endAdornment: (
                  <Fade in={comment !== product.comment}>
                    <IconButton onClick={() => fetchComment()}>
                      <AddRounded />
                    </IconButton>
                  </Fade>
                ),
              }}
              onChange={handleComment}
              onKeyPress={handleKeyPress}
            />
          </TableCell>
        )}
        {!commentZone && (
        <TableCell
          style={{
            maxWidth: '12vw',
            textAlign: 'left',
            paddingLeft: '0.4em',
            textOverflow: 'ellipsis',
          }}
          className={classes.noPadding}
        >
          <span>
            <div className="d-flex">
              {handleProductImage(product)}

              <div className="d-flex justify-content-center flex-column">
                <div
                  className="d-flex"
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    paddingLeft: '0.4em',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {product.productname}
                </div>
                <div>
                  {product.productcode && (
                  <span
                    style={{
                      padding: '3px 5px',
                      backgroundColor: '#f6f6f7',
                      borderRadius: '3px',
                      marginRight: '10px',
                    }}
                  >
                    {product.productcode}
                  </span>
                  )}
                  {' '}
                  {product.tpo_id_label && (
                  <span
                    style={{
                      padding: '3px 5px',
                      backgroundColor: '#f6f6f7',
                      borderRadius: '3px',
                    }}
                  >
                    {product.tpo_id_label}
                  </span>
                  )}
                </div>
              </div>
            </div>
          </span>
        </TableCell>
        )}
        {!commentZone && (
          <TableCell className={classes.noPadding}>
            <div className="cart_input cart_input_qty d-flex justify-content-center">
              {(posTypeTab === 'Vender' || enableEditOrder)
                && !(
                  productid === shipping_id
                  && product.index !== 'gastos_de_envio_por_cotizar'
                )
                && (product.pl_products_batch_status
                  !== 'pl_products_batch_status_serial'
                  || !product.TreebesLotes
                  || Object.keys(product.TreebesLotes)?.length === 0
                  || pos_auto_alm !== 'Siempre'
                  || cartActionSelected !== 'CobrarVender'
                )
                && (
                  <button
                    tabIndex={-1}
                    type="button"
                    className="cart__increase-decrease-product"
                    data-crmid={product.crmid || productid}
                    data-row={product.row}
                    onClick={
                      canDecreaseProductInCart(product)
                        ? dispatchDecreaseProductInCart
                        : () => {}
                    }
                    disabled={canDecreaseProductInCart(product) === false}
                  >
                    <>-</>
                  </button>
                )}
              <input
                type="number"
                step="1"
                min={parseFloat(product.sales_unit).toFixed(2) || 1}
                value={Math.abs(inputValue)}
                inputRef={debounceInputRef}
                onFocus={event => event.target.select()}
                data-crmid={product.crmid || productid}
                data-row={product.row}
                data-selection="selection"
                disabled={
                  product.crmid === shipping_id
                  || productid === shipping_id
                  || (!product.TreebesLotes || Object.keys(product.TreebesLotes)?.length > 0 || product.TreebesLotes.length > 0)
                  || product.pl_products_batch_status
                    === 'pl_products_batch_status_serial'
                    ? true
                    : !(posTypeTab === 'Vender' || enableEditOrder)
                }
                onChange={(event) => {
                  setInputValue(event.target.value);
                }}
              />
              {(posTypeTab === 'Vender' || enableEditOrder)
                && !(
                  productid === shipping_id
                  && product.index !== 'gastos_de_envio_por_cotizar'
                )
                && (product.pl_products_batch_status
                  !== 'pl_products_batch_status_serial'
                  || !product.TreebesLotes
                  || Object.keys(product.TreebesLotes)?.length === 0
                  || pos_auto_alm !== 'Siempre'
                  || cartActionSelected !== 'CobrarVender'
                )
                && (
                  <button
                    tabIndex={-1}
                    type="button"
                    className="cart__increase-decrease-product"
                    data-crmid={product.crmid || productid}
                    data-row={product.row}
                    data-count={0}
                    onClick={(e) => {
                      e.persist();
                      e.target.dataset.count = (parseInt(e.target.dataset.count) || 0) + 1;
                      clearTimeout(timeout);
                      clearTimeout(countTimeout);
                      timeout = setTimeout(
                        dispatchIncreaseProductInCart,
                        200,
                        e,
                      );
                      countTimeout = setTimeout(
                        () => {
                          e.target.dataset.count = 0;
                        },
                        200,
                        e,
                      );
                    }}
                  >
                    <>+</>
                  </button>
                )}
            </div>

            {productStock?.qtyinstock && (
              <div>
                <span>
                  {`Stock: ${Number.parseInt(
                  productStock?.qtyinstock,
                  )}`}
                </span>
              </div>
            )}
          </TableCell>
        )}
        {!commentZone && (
          <TableCell className={classes.noPadding}>
            <DebounceInput
              type="number"
              step="1"
              data-row={product.row}
              disabled={
                pos_can_change_price === '0' ? true : !enablePrice
              }
              value={numberFormat(product.unit_price)}
              data-crmid={product.crmid || productid}
              onFocus={event => event.target.select()}
              placeholder={Math.abs(product.unit_price)}
              className="cart_input cart_input_price"
              onChange={e => dispatchUpdateProductPriceInCart(e, true)}
              min={0}
              debounceTimeout={300}
            />
          </TableCell>
        )}
        {!commentZone && Boolean(cartProducts.some(p => p.discount_percent > 0)) && (
          <TableCell className={classes.noPadding}>
            {product.discount_percent > 0 ? (
              <Tooltip
                title={`Precio sin descuento: $${formatMoney(
                  product.unit_price * product.quantity,
                )}`}
              >
                {edit ? (
                  <Chip
                    clickable
                    size="small"
                    color="primary"
                    variant="outlined"
                    onDelete={removeDiscount}
                    label={`${product.discount_percent} %`}
                    onClick={(e) => {
                      setDiscountAnchor(e.currentTarget);
                    }}
                  />
                ) : (
                  <Chip
                    size="small"
                    icon={<Done />}
                    color="secondary"
                    label={`${product.discount_percent} %`}
                  />
                )}
              </Tooltip>
            ) : (
              <Chip
                size="small"
                variant="outlined"
                label={`${product.discount_percent} %`}
              />
            )}
          </TableCell>
        )}
        {!commentZone && (
          <TableCell className={classes.noPadding}>
            <Tooltip
              placement="start-left"
              title={(
                <span
                  style={{ display: 'flex', flexDirection: 'column', gap: 5 }}
                >
                  <span>
                    {`P. Unitario: $${formatMoney(product.unit_price)}`}
                  </span>
                  <span>
                    {`P. Neto Unitario: $${formatMoney(
                      (product.priceWithTaxes * product.quantity)
                        / product.quantity,
                    )}`}
                  </span>
                  <span>
                    {`P. Total: $${formatMoney(
                      product.unit_price * product.quantity,
                    )}`}
                  </span>
                  <span>
                    {`P. Neto Total: $${formatMoney(
                      product.priceWithTaxes * product.quantity,
                    )}`}
                  </span>
                </span>
              )}
            >
              <span style={{ fontSize: 16, color: '#23A1DB' }}>
                {`$ ${getTotalPrice(product) < 0 ? 0.01 : formatMoney(getTotalPrice(product))}`}
              </span>
            </Tooltip>
          </TableCell>
        )}
        <TableCell
          align="right"
          style={{ width: '8vw' }}
          className={classes.noPadding}
        >
          <CartListOptions
            user={user}
            edit={edit}
            product={product}
            commentZone={open}
            setCommentZone={setOpen}
            getPriceByPricebook={getPriceByPricebook}
            dispatchSetSingleDiscount={dispatchSetSingleDiscount}
            dispatchRemoveProductInCart={dispatchRemoveProductInCart}
          />
        </TableCell>
        <Popover
          id={idPop}
          open={openPop}
          anchorEl={discountAnchor}
          onClose={() => setDiscountAnchor(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <div className="p-1">
            <Input
              style={{
                textAlign: 'right',
                width: '50px',
              }}
              autoFocus
              inputComponent={Percentage}
              defaultValue="Descuento %"
              inputProps={{
                'aria-label': 'description',
              }}
              onBlur={setSingleDiscountOnBlur}
              onChange={setSingleDiscountOnChange}
              onKeyPress={setSingleDiscountKeyPress}
            />
          </div>
        </Popover>
      </TableRow>
      {product.pl_products_batch_status === 'pl_products_batch_status_serial'
        && Object.keys(product.TreebesLotes)?.length > 0
        && pos_auto_alm === 'Siempre'
        && cartActionSelected === 'CobrarVender' && (
          <TableRow>
            <TableCell
              style={{ padding: 0 }}
              colSpan={product.discount_percent > 0 ? 6 : 5}
            >
              <ProductSerials product={product} />
            </TableCell>
          </TableRow>
      )}
      {product.comment && (
        <TableRow>
          <TableCell colSpan={product.discount_percent > 0 ? 6 : 5}>
            <div className="d-flex align-items-center" style={{ gap: 15 }}>
              <FontAwesomeIcon
                color="gray"
                icon={faCommentAlt}
                style={{ fontSize: '16px' }}
              />
              <textarea
                disabled
                rows={3}
                style={{
                  width: '100%',
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
              >
                {product.comment}
              </textarea>
            </div>
          </TableCell>
        </TableRow>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Agregar comentario al producto
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <strong>{product.productname}</strong>
            <TextField
              autoFocus
              disabled={!edit}
              value={comment}
              variant="outlined"
              multiline
              className="w-100 h-100 pt-2"
              defaultValue={product.comment}
              placeholder="Escriba un comentario al producto"
              onChange={handleComment}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={!edit} onClick={handleAccept} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

CartListRow.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  rowClasses: PropTypes.object.isRequired,
  handleKeyTopSearchBar: PropTypes.func.isRequired,
  dispatchDecreaseProductInCart: PropTypes.func.isRequired,
  dispatchIncreaseProductInCart: PropTypes.func.isRequired,
  dispatchRemoveProductInCart: PropTypes.func.isRequired,
};
CartListRow.defaultProps = {};

export default CartListRow;
